import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Providers/AuthProvider";
import { IoArrowForwardOutline } from "react-icons/io5";

import { FiArrowLeft } from "react-icons/fi";
import { useEffect, useState } from "react";
import axios from "axios";
import UserUtils from "../Utils/UserUtils";
export default function UserHeader() {
  const { currentUser } = useAuth();
  const [payments, setPayments] = useState([]);
  const usermailname = currentUser.displayName;
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_SERVER_URL + "/user/get-payment-history", {
        email: currentUser.email,
      })
      .then(function (response) {
        setPayments(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentUser.email]);

  return (
    <>
      <div id="paymentnavigate-header">
        <button
          onClick={() => {
            navigate("/user/payment-history");
          }}
        >
          <FiArrowLeft /> Dashboard
        </button>
        <div className="logout-container flex gap-1 looutref">
          <img className="my-auto w-7 invert" src="/icons/profile.png" alt="" />
          <p>{payments.length > 0 ? payments[0].billing_name : usermailname}</p>
          

          {/* Logout Dropdown */}
          <div className="logout-dropdown">
            <p onClick={() => UserUtils.signOut()}>
              Logout <IoArrowForwardOutline />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
