import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import "./instaform.css";
import { useNavigate } from "react-router-dom";
import InstaLoader from "./InstaLoader";

const validationSchema = Yup.object({
  modeOfLearning: Yup.string().required("Please select yes or no"),
  durationConfirmation: Yup.string().required("Please select yes or no"),
  feeCommitment: Yup.string().min(1, "Select at least one option"),
  fullName: Yup.string().required("Please enter your full name"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
    .required("Please enter your contact number"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Please enter your email"),
  streetAddress: Yup.string().required("Please enter your street address"),
  whyDmx: Yup.string().required("Please specify your interest"),
});

const MultiStepDmxform = () => {
  const [step, setStep] = useState(1);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const navigate = useNavigate();

  const nextStep = async (validateForm, setTouched) => {
    const errors = await validateForm();
    const relevantFields = {
      1: ["fullName", "phoneNumber", "email", "streetAddress"],
      2: ["modeOfLearning", "durationConfirmation"],
      3: ["feeCommitment"],
      4: ["whyDmx"],
    };

    const currentErrors = relevantFields[step].reduce((acc, field) => {
      if (errors[field]) acc[field] = errors[field];
      return acc;
    }, {});

    if (Object.keys(currentErrors).length === 0) {
      setStep((prevStep) => prevStep + 1);
    } else {
      setTouched(
        relevantFields[step].reduce((acc, field) => {
          acc[field] = true;
          return acc;
        }, {})
      );
    }
  };

  const prevStep = () => setStep(step - 1);

  return (
    <div className="multistepform-container">
      <div className="instaRightform" id="dmxbg">
        <h3>Digital Marketing Xperential</h3>

        <p>
          Learn to become a real marketer with a blend of tools, creativity,
          analytics and content skills. Get trained by the experienced tutors
          who make learning a game.
        </p>
      </div>
      <Formik
        initialValues={{
          modeOfLearning: "",
          durationConfirmation: "",
          feeCommitment: "",
          fullName: "",
          phoneNumber: "",
          email: "",
          streetAddress: "",
          whyDmx: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmissionStatus("");
          axios
            .post(`${process.env.REACT_APP_SERVER_URL}/instaForm/dmx`, values)
            .then((response) => {
              console.log("Data submitted:", response);
              setSubmissionStatus("success");
              navigate("/instaDMXthankyou");
            })
            .catch((error) => {
              console.error("Error submitting data:", error);
              setSubmissionStatus("failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting, validateForm, setTouched }) => (
          <Form className="instaform">
            {step === 1 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 0%, #807e7e 0%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Full Name <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="fullName" className="field" />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>
                    Phone number (preferably WhatsApp Number)
                    <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="phoneNumber" className="field" />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>
                    Email <span className="required-star">*</span>
                  </label>
                  <Field type="email" name="email" className="field" />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field">
                  <label>
                    Street Address <span className="required-star">*</span>
                  </label>
                  <Field type="text" name="streetAddress" className="field" />
                  <ErrorMessage
                    name="streetAddress"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="singlenextinstabtn">
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 2 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 25%, #807e7e 25%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Your preferred mode of Learning?{" "}
                    <span className="required-star">*</span>
                  </label>
                  <div className="readiofeild">
                    <div>
                      <Field
                        type="radio"
                        name="modeOfLearning"
                        value="Online"
                        className="readi-btn"
                      />
                      <label className="readibtn-label radiodmxmode">
                        Online
                      </label>
                    </div>
                    <div>
                      <Field
                        type="radio"
                        name="modeOfLearning"
                        value="Offline"
                        className="readi-btn"
                      />
                      <label className="readibtn-label radiodmxmode">
                        Offline
                      </label>
                    </div>
                  </div>
                  <ErrorMessage
                    name="modeOfLearning"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="form-field" style={{ marginTop: "30px" }}>
                  <label>
                    The Boot camp duration would be 100+ days & 3 hours per day.
                    Are you ready for that?{" "}
                    <span className="required-star">*</span>
                  </label>
                  <div className="readiofeild">
                    <Field
                      type="radio"
                      name="durationConfirmation"
                      value="Yes"
                      className="readi-btn"
                    />
                    <label className="readibtn-label">Yes</label>
                    <Field
                      type="radio"
                      name="durationConfirmation"
                      value="No"
                      className="readi-btn"
                    />
                    <label className="readibtn-label">No</label>
                    <Field
                      type="radio"
                      name="durationConfirmation"
                      value="yet to decide"
                      className="readi-btn"
                    />
                    <label className="readibtn-label">yet to decide</label>
                  </div>
                  <ErrorMessage
                    name="durationConfirmation"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 3 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 50%, #807e7e 50%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Fee for DMX bootcamp is Rs. 88,000/-, what's your thought?{" "}
                    <span className="required-star">*</span>
                  </label>
                  <div
                    role="group"
                    aria-labelledby="radio-group"
                    className="selectinstaocc"
                  >
                    <label className="readiofeelabel">
                      <Field
                        type="radio"
                        name="feeCommitment"
                        value="Affordable"
                        className="checkadiofee"
                      />
                      Affordable
                    </label>
                    <label className="readiofeelabel">
                      <Field
                        type="radio"
                        name="feeCommitment"
                        value="Better with Instalments"
                        className="checkadiofee"
                      />
                      Better with Instalments
                    </label>
                    <label className="readiofeelabel">
                      <Field
                        type="radio"
                        name="feeCommitment"
                        value="Not Affordable"
                        className="checkadiofee"
                      />
                      Not Affordable
                    </label>
                  </div>
                  <ErrorMessage
                    name="feeCommitment"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button
                    type="button"
                    onClick={() => nextStep(validateForm, setTouched)}
                    disabled={isSubmitting}
                  >
                    Next <FiArrowRight className="arrow-insta" />
                  </button>
                </div>
              </div>
            )}

            {step === 4 && (
              <div
                className="stepform"
                style={{
                  borderImage:
                    "linear-gradient(to right, #fcd00e 80%, #807e7e 80%) 1",
                }}
              >
                <div className="form-field">
                  <label>
                    Why do you want to learn Digital Marketing?{" "}
                    <span className="required-star">*</span>
                  </label>
                  <Field
                    as="textarea"
                    name="whyDmx"
                    className="field textareafeild"
                  />
                  <ErrorMessage
                    name="whyDmx"
                    component="div"
                    className="error"
                  />
                </div>
                <div className="btninstagroup">
                  <button type="button" onClick={prevStep}>
                    <FiArrowLeft className="arrow-insta" /> Previous
                  </button>
                  <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? (
                      <InstaLoader />
                    ) : submissionStatus === "failed" ? (
                      "Failed to Submit"
                    ) : submissionStatus === "success" ? (
                      "Submitted Form"
                    ) : (
                      "Submit Form"
                    )}
                  </button>
                </div>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MultiStepDmxform;
