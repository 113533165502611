import React from "react";
import { useLocation } from "react-router-dom";
import { GoArrowRight } from "react-icons/go";

const Blog = () => {
  const location = useLocation();
  const { data } = location.state || {};
  console.log(data);

  return (
    <div className="pt-[100px] bg-[#333]">
      {data && (
        <div className="bg-[#333] blogout">
          {data.sections.map((section, index) => (
            <div key={index}>
              <h2 className="text-[#f7d00d] mt-[20px] mt-[10px] text-xl">
                {section.title}
              </h2>
              <p className="text-xxl">{section.subHeading}</p>
              {section.imageURL && <img src={section.imageURL} alt="" />}
              <p className="mt-[10px]">{section.content}</p>
              {section.referenceURL && (
                <div className="flex items-center gap-[10px] opacity-50">
                  <a href={section.referenceURL} className="underline">
                    Refrence Link
                  </a>
                  <GoArrowRight />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;
