import { useNavigate } from "react-router-dom";
import { FiArrowRight } from "react-icons/fi";
export default function TermsAndConditions() {
  const navigate = useNavigate();
  return (
    <section>
      <div className="policy-page-container">
        <div className="policy-heading">
          <h1 className="heading">Terms &amp; Conditions</h1>
        </div>
        <div className="policy-body">
          <p>
            These terms and conditions outline the rules and regulations for
            joining Hashpro Academy’s programs. Hashpro Academy is a part of
            Procave Edtech Pvt Ltd.{" "}
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to join the programs&nbsp;if you do not
            agree to take all of the terms and conditions stated on this page.{" "}
          </p>
          <p>
            This document ("Terms &amp; Conditions") constitutes a legal
            agreement between you/your ("student/candidate") and Hashpro Academy
            ("the Academy," "we," "our," or "us"), governing the joining and
            journey of a student. By accessing the programs (”all the programs
            offered at the academy’), you agree to be bound by these Terms.
            Please read them carefully before proceeding. Any use of the above
            terminology or other words in the singular, plural, capitalization
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to same.
          </p>
          <h2>1. Enrollment</h2>
          <p>
            To access and enroll in the program, students are required to
            register with the Academy through designated platforms. Enrollment
            entails providing accurate and complete information. The process of
            enrolling in any program offered by the academy is subject to
            acceptance of these terms and conditions. Students under the
            circumstances of health emergency, unfortunate event of a
            bereavement within the family, students may request a temporary
            pause or extension of their enrollment period, however it shall
            remain at the sole discretion of the academy.{" "}
          </p>
          <h2>2. Program related </h2>
          <ul>
            <li>
              The academy reserves the right to modify program content,
              schedules, or instructors as necessary.
            </li>
            <li>
              Programs are offered for a specified duration as indicated in the
              program materials. Extension of the duration may be granted under
              exceptional circumstances.
            </li>
            <li>
              Successful completion of a program results in the awarding of a
              certificate of completion, subject to meeting all requirements:
              Attendance, Class Participation, Task Completion and overall
              performance.{" "}
            </li>
            <li>
              Students are expected to have 80% of attendance regardless of the
              program they choose.
            </li>
            <li>
              Course assessment methods may include quizzes, assignments,
              projects, or exams, as determined by the course instructor.
            </li>
            <li>
              Grading policies and criteria will be communicated to students at
              the beginning of each course.
            </li>
            <b>Withdrawal Policy : </b>
            <ul>
              <li>
                Withdrawal before completion results in no provision of course
                materials or certificates.
              </li>
              <li>
                Completion of the entire course is necessary to obtain a
                certificate.
              </li>
              <li>No refunds are provided upon withdrawal.</li>
              <li>
                Certificates are non-transferable and should be authenticated by
                the recipient.
              </li>
            </ul>
            <li>
              Transfer between batches/slots or carry-forward of registration
              will not be entertained and will be at the sole discretion of the
              academy.
            </li>
          </ul>
          <h2>3. Pricing and Payment</h2>
          <ul>
            <li>
              Fees are payable in advance and may vary based on the selected
              program and details for the same shall be shared at the time of
              call/registration fee payment. Third-party payment gateways may be
              used for fee collection. Fees are non-transferable and
              non-refundable unless otherwise specified in the refund policy.
            </li>
            <li>
              All fees must be paid in full prior to the commencement of the
              course unless otherwise specified.
            </li>
            <li>Total Program fee includes Enrollment fee and Training Fee.</li>
            <li>
              Program Fee doesn’t include Domain Registration Fee, Hosting
              Charges, SSL Charges, Theme Purchases, Any Paid tools/ Plugins,
              Any Licensed Software Purchases &amp; Installations.
            </li>
          </ul>
          <h2>4. Refund Policy</h2>
          <p>
            The Academy's refund policy states the conditions under which
            refunds may be issued, if applicable. Refunds are subject to the
            Academy's discretion and may be provided in cash or credit,
            depending on circumstances.
            <button
              id="refundpolicy"
              onClick={() => navigate("/cancellation-and-refund-policy")}
            >
              More about Refund policy <FiArrowRight className="arrow-to" />
            </button>
          </p>
          <h2>5. Intellectual Property Rights</h2>
          <p>
            All program materials, including but not limited to lectures,
            presentations, images, videos and handouts, are the intellectual
            property of the academy and may not be reproduced or distributed
            without permission. Students are granted access to few handouts use
            the content for personal, non-commercial purposes.{" "}
          </p>
          <h2>6. Conduct and Guidelines</h2>
          <p>
            Students are expected to maintain a standard of professionalism and
            integrity during the program. Prohibited actions include but are not
            limited to harassment, dissemination of harmful content,
            unauthorized use of credentials, and infringement of intellectual
            property rights.
          </p>
          <p>
            The academy reserves the right to terminate a student's enrollment
            from a program for violations of these terms and conditions or for
            other reasons deemed appropriate by academy management.
          </p>
          <h2>7. Personal Information and Data Security</h2>
          <p>
            The Academy's privacy policy outlines the collection, use, and
            protection of student’s personal information. By accessing the
            program, students consent to the processing of their data in
            accordance with the privacy policy. The academy respects the privacy
            of its students and complies with all relevant privacy laws and
            regulations. Personal information provided by students will only be
            used for the purposes of administering courses and communicating
            with students.
          </p>
          <h2>8. Third Party Apps</h2>
          <p>
            Links to third-party websites or applications may be provided for
            additional resources. Students acknowledge that the Academy does not
            endorse or control these third-party sites and assumes no liability
            for their content or services.
          </p>
          <h2>9. Indemnity</h2>
          <p>
            Students agree to indemnify and hold the Academy harmless from any
            losses, claims, damages, or liabilities arising at the time of their
            program, violation of these Terms, or infringement of any rights.
          </p>
          <h2>10. Limitation of Liability</h2>
          <p>
            In no event shall the academy be liable for any indirect,
            incidental, special, consequential, or punitive damages, or any loss
            of profits or revenues, whether incurred directly or indirectly,
            arising from the use of or reliance on course materials.
          </p>
          <h2>11. Disclaimer of Warranties</h2>
          <p>
            The academy makes no warranties or representations about the
            accuracy or completeness of course materials and disclaims all
            liability for any loss or damage arising from reliance on such
            materials.
          </p>
          <h2>12. Modification of Terms of Use</h2>
          <p>
            The Academy may modify or update these Terms at its discretion.
            Students are responsible for reviewing these Terms periodically for
            changes. Continued use of the Services constitutes acceptance of the
            modified Terms.
          </p>
          <h2>13. Applicable Law and Jurisdiction</h2>
          <p>
            These Terms are governed by the laws of Ward No 79 of GHMC. Any
            disputes arising under these Terms shall be subject to the exclusive
            jurisdiction of the courts of Chief Judge Court, Hyderabad.
          </p>
          <h2>14. Miscellaneous</h2>
          <ul>
            <li>
              These Terms constitute the entire agreement between Students and
              the Academy.
            </li>
            <li>
              Failure to enforce any provision of these Terms does not waive the
              right to enforce such provision in the future.
            </li>
            <li>
              Invalidity of any provision does not affect the validity of the
              remaining provisions.
            </li>
            <li>
              Notices under these Terms shall be in writing and deemed received
              when delivered.
            </li>
            <li>
              Academy holds complete right to consider the change in program
              timings, dates, and faculty availability as per the circumstances.
              However, every change shall be considered from every aspect and
              shall be communicated.{" "}
            </li>
            <li>
              Academy reserves the right to cancel/ forfeit the registration of
              the student without any notice in case of indiscipline, regular
              absenteeism, attendance falling below 80% and such others reasons.
            </li>
            <li>
              Academy cannot be held responsible for any loss or damage of
              personal property of any student. Any cost of damage caused by
              improper treatment or misuse of any equipment, devise or property
              of the academy shall be reimbursed in full by the student liable
              for such damage.
            </li>
          </ul>
          <h2>15. Grievance Redressal and Grievance Officer</h2>
          <p>
            For queries or complaints, students may contact the designated
            Grievance Officer. The Academy aims to acknowledge and resolve
            grievances within a reasonable timeframe.
          </p>
        </div>
      </div>
    </section>
  );
}
