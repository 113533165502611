import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GridLoader from "react-spinners/GridLoader";
const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getBlogs = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/blogRoutes/get-blogs`)
      .then((r) => {
        setBlogs(r.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching blogs:", err);
      });
  };

  useEffect(() => {
    getBlogs();
  }, []);

  if (loading) {
    return (
      <div className=" loadingScreen">
        <GridLoader color="#f8d00d" size={15} />
      </div>
    );
  }

  return (
    <div className="pt-[100px] allblogs">
      <h2 className="text-center text-[#f8d00d] text-2xl">Blogs</h2>
      <div className="blogscrad-container">
        {blogs.map((e, index) => {
          return (
            <div key={index}>
              <div className="Blog-card">
                <img src={e.sections[0].imageURL} alt="" />
                <div className="bloContnet">
                  <h3>{e.sections[0].title}</h3>
                  <p> Author - {e.author}</p>
                  <p>
                    Date of posted :{" "}
                    {new Date(e.createdAt).toLocaleDateString()}{" "}
                    {new Date(e.createdAt).toLocaleTimeString()}
                  </p>

                  <button
                    onClick={() =>
                      navigate(`/blog/${e.sections[0].title}`, {
                        state: { data: e },
                      })
                    }
                  >
                    Read More
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllBlogs;
