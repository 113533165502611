import React, { createContext, useState, useEffect, useContext } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Create context for authentication
const MasterClassContext = createContext();

// MasterClassProvider component
export const MasterClassProvider = ({ children }) => {
    const [masterClassIsOpen, setMasterClassOpen] = useState(false);
    const [masterClassDate, setMasterClassDate] = useState('');
    const [masterClassPage, setMasterClassPage] = useState('calendar');
    const [confirmDateOpen, setConfirmDateOpen] = useState(false);
    const [masterClassSlot, setMasterClassSlot] = useState('');
    const [masterClassSeats, setMasterClassSeats] = useState([])

    const switchMasterClassOpen = () => {
        setMasterClassOpen(!masterClassIsOpen)
    }

    useEffect(() => {
        // console.log(masterClassSeats)
    }, [masterClassSeats])

    // Provide authentication context values
    const MasterClassOptions = {
        setMasterClassDate,
        masterClassPage,
        masterClassDate,
        masterClassIsOpen,
        setMasterClassPage,
        setMasterClassOpen,
        switchMasterClassOpen,
        confirmDateOpen,
        setConfirmDateOpen,
        masterClassSlot,
        setMasterClassSlot,
        masterClassSeats,
        setMasterClassSeats
    };

    useEffect(() => {
        // console.log(masterClassIsOpen)
    }, [masterClassIsOpen])

    //this is for switching to form after user has selected a date
    useEffect(() => {
        // console.log(MasterClassOptions)

        if (masterClassDate != '') {
            setConfirmDateOpen(true)
        }

    }, [masterClassDate])

    useEffect(() => {
        // console.log('Master Class Slot : ', masterClassSlot)
    }, [masterClassSlot])

    return (
        <MasterClassContext.Provider value={MasterClassOptions}>
            {children}
        </MasterClassContext.Provider>
    );

};

// Custom hook to use auth context
export const useMasterClass = () => useContext(MasterClassContext);