import React from "react";
import { FaFileDownload } from "react-icons/fa";


const InstaDmxThankyou = () => {


  return (
    <div className="instathanks">
      <div className="right-div">
        <svg
          version="1.0"
          xmlns="http://www.w3.org/2000/svg"
          width="70px"
          height="70px"
          viewBox="0 0 1280.000000 1185.000000"
          preserveAspectRatio="xMidYMid meet"
          style={{ fill: "black" }}
        >
          <g
            transform="translate(0.000000,1185.000000) scale(0.100000,-0.100000)"
            style={{ fill: "black" }}
            stroke="none"
          >
            <path
              d="M12525 11747 c-1052 -607 -2241 -1476 -3359 -2456 -901 -790 -1862
  -1742 -2752 -2726 -614 -680 -1276 -1471 -1874 -2240 -208 -268 -746 -986
  -915 -1223 -87 -122 -135 -181 -145 -178 -8 2 -769 430 -1690 950 l-1675 947
  -38 -43 c-20 -24 -42 -50 -47 -59 -9 -16 133 -182 3754 -4381 l291 -338 40 0
  40 0 227 453 c1121 2231 2222 4068 3471 5792 1377 1899 2936 3648 4690 5259
  125 115 227 212 227 216 0 5 -69 103 -82 116 -2 1 -75 -39 -163 -89z"
            />
          </g>
        </svg>
      </div>
      <h2>Submitted</h2>
      <p className="text-center mt-2 w-[90%]">
        Thankyou! We will reach you out soon.
      </p>
      <a href="https://www.hashproacademy.com/global/dmx/DMX-Brochure.pdf">
        <button>
          <FaFileDownload id="downbro" /> Dowload Brochure
        </button>
      </a>
    </div>
  );
};

export default InstaDmxThankyou;
