import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
} from "react";
import sections from "../pages/HAT/components/sections";

const TestContext = createContext();

const TestProvider = ({ children }) => {
  const [sectionID, setSectionID] = useState(0);
  const [pageID, setPageID] = useState(0);
  const [globalQuestions, setGlobalQuestions] = useState(sections);
  const [total, setTotal] = useState(0);
  const [attempted, setAttempted] = useState(0);
  const [percentage, setPercentage] = useState(0);
  const [godView, setGodView] = useState(false);

  useEffect(() => {
    let totalAnswers = 0;
    let emptyStringAnswers = 0;

    globalQuestions.forEach((section) => {
      // Iterate over each page
      section.pages.forEach((page) => {
        // Iterate over each question
        page.questions.forEach((question) => {
          totalAnswers++; // Increment total answer count
          if (question.answer === "") {
            emptyStringAnswers++; // Increment empty string answer count
          }
        });
      });
    });

    const attemptedQuestions = totalAnswers - emptyStringAnswers;
    setTotal(totalAnswers);
    setAttempted(attemptedQuestions);
    const percentageAttempted = (attemptedQuestions / totalAnswers) * 100;
    setPercentage(Math.floor(percentageAttempted));
    // console.log('UPDATED GLOBAL IN PROVIDER');
  }, [globalQuestions]);

  const setSectionAndPage = (section, page) => {
    setSectionID(section);
    setPageID(page);
  };

  const toggleGodView = () => {
    setGodView(!godView);
  };

  const setGlobalAnswers = useMemo(
    () => (newJSON) => {
      setGlobalQuestions([...newJSON]);
    },
    [setGlobalQuestions]
  );

  const resetSectionAndPage = () => {
    setSectionID(null);
    setPageID(null);
  };

  return (
    <TestContext.Provider
      value={{
        sectionID,
        pageID,
        globalQuestions,
        setSectionAndPage,
        resetSectionAndPage,
        setGlobalAnswers,
        total,
        attempted,
        percentage,
        godView,
        toggleGodView,
      }}
    >
      {children}
    </TestContext.Provider>
  );
};

const useTestContext = () => {
  const context = useContext(TestContext);
  if (!context) {
    throw new Error("useTestContext must be used within a TestProvider");
  }
  return context;
};

export { TestProvider, useTestContext };
