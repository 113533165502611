import React, { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import gsap from "gsap/all";
import SplitType from "split-type";

// To remove console logs in Production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_KrOLsZh7hYivy12TjZyRKlK328N4huw",
  authDomain: "hashpro-academy-e69e8.firebaseapp.com",
  projectId: "hashpro-academy-e69e8",
  storageBucket: "hashpro-academy-e69e8.appspot.com",
  messagingSenderId: "834033336538",
  appId: "1:834033336538:web:e977f77e97e134fad3e5e3",
  measurementId: "G-J3QW0G4QYX",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// reportWebVitals();
