import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import axios from "axios";

const validationSchema = Yup.object().shape({
  author: Yup.string().required("Author name is required"),
  sections: Yup.array().of(
    Yup.object().shape({
      title: Yup.string(),
      subHeading: Yup.string(),
      image: Yup.mixed().nullable(),
      content: Yup.string(),
      referenceURL: Yup.string(),
    })
  ),
});

const BlogForm = () => {
  const [fileNames, setFileNames] = useState(Array(6).fill("No file chosen"));

  return (
    <div className="blogform">
      <h2>Create Blog Form with 6 Repeated Sections</h2>
      <Formik
        initialValues={{
          author: "", // New field for blog author's name
          sections: Array(6).fill({
            title: "",
            subHeading: "", // New sub-heading field
            image: null,
            content: "",
            referenceURL: "",
          }),
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formData = new FormData();

          // Append author's name
          formData.append("author", values.author);

          // Append section data
          values.sections.forEach((section, index) => {
            formData.append(`sections[${index}][title]`, section.title);
            formData.append(
              `sections[${index}][subHeading]`,
              section.subHeading
            );
            formData.append(`sections[${index}][image]`, section.image);
            formData.append(`sections[${index}][content]`, section.content);
            formData.append(
              `sections[${index}][referenceURL]`,
              section.referenceURL
            );
          });

          axios
            .post(
              `${process.env.REACT_APP_SERVER_URL}/blogRoutes/add-blog`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              console.log("Response Status:", response.status);
            })
            .catch((err) => console.log("Error:", err));
        }}
      >
        {({ values, setFieldValue }) => (
          <Form encType="multipart/form-data">
            <div>
              <label>Author Name:</label>
              <Field name="author" placeholder="Enter your name" />
              <ErrorMessage name="author" component="div" className="error" />
            </div>

            <FieldArray name="sections">
              {() => (
                <div>
                  {values.sections.map((section, index) => (
                    <div key={index} className="section">
                      <h3>Section {index + 1}</h3>

                      <div>
                        <label>Title:</label>
                        <Field
                          name={`sections.${index}.title`}
                          placeholder="Title"
                        />
                        <ErrorMessage
                          name={`sections.${index}.title`}
                          component="div"
                          className="error"
                        />
                      </div>

                      <div>
                        <label>Sub Heading:</label>
                        <Field
                          name={`sections.${index}.subHeading`}
                          placeholder="Sub Heading"
                        />
                        <ErrorMessage
                          name={`sections.${index}.subHeading`}
                          component="div"
                          className="error"
                        />
                      </div>

                      <div>
                        <label>Image:</label>
                        <input
                          type="file"
                          name={`sections.${index}.image`}
                          onChange={(event) => {
                            const file = event.currentTarget.files[0];
                            setFieldValue(`sections.${index}.image`, file);
                            const newFileNames = [...fileNames];
                            newFileNames[index] = file
                              ? file.name
                              : "No file chosen";
                            setFileNames(newFileNames);
                          }}
                        />
                        <div className="file-name">
                          Selected File: {fileNames[index]}
                        </div>
                        <ErrorMessage
                          name={`sections.${index}.image`}
                          component="div"
                          className="error"
                        />
                      </div>

                      <div>
                        <label>Content:</label>
                        <Field
                          as="textarea"
                          name={`sections.${index}.content`}
                          placeholder="Content"
                        />
                        <ErrorMessage
                          name={`sections.${index}.content`}
                          component="div"
                          className="error"
                        />
                      </div>

                      <div>
                        <label>Reference URL:</label>
                        <Field
                          name={`sections.${index}.referenceURL`}
                          placeholder="https://example.com"
                        />
                        <ErrorMessage
                          name={`sections.${index}.referenceURL`}
                          component="div"
                          className="error"
                        />
                      </div>

                      <hr />
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>

            <button type="submit" className="submit-btn">
              Submit Blog Form
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BlogForm;
