import { Link } from "react-router-dom";
import { useAuth } from "../../Providers/AuthProvider";
import Payment from "../Payments/Payment";
import "./userdashboard.css";

export default function UserDashboard() {
  const { currentUser } = useAuth();
  return (
    <section id="back">
      <section className="def-from-text">
        <h2 id="welcome">Welcome To</h2>
        <h1 id="h-p">Hashpro Academy</h1>
        <p>Learn without limits </p>
      </section>
      <section id="userdash-default-payform">
        <Payment />
      </section>
    </section>
  );
}
