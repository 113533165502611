import "./dmx.min.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "../../Global_Components/css/animate.css";
import { useLayoutEffect } from "react";
import gsap, { ScrollTrigger } from "gsap/all";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "../../Global_Components/faq.css";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../Global_Components/global_scripts.js";
import { useFormik } from "formik";
import { useLocation } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Loader from "../../Global_Components/Loader";

export default function DMX() {
  const [formStatus, setFormStatus] = useState("default");

  const [utmSource, setUtmSource] = useState("Website");

  const getUTMParameter = (name) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  };

  useEffect(() => {
    const source = getUTMParameter("utm_source");
    if (source) {
      setUtmSource(source);
    }
  }, []);

  console.log(utmSource);

  const [learnWeeks, setLearnWeeks] = useState({
    week1: false,
    week2: false,
    week3: false,
    week4: false,
    week5: false,
    week6: false,
    week7: false,
    week8: false,
    week9: false,
    week10: false,
    week11: false,
    week12: false,
    week13: false,
    week14: false,
    week15: false,
    week16: false,
    week17: false,
    week18: false,
    week19: false,
    week20: false,
  });

  useLayoutEffect(() => {
    //animate css
    const intersectionCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && entry.intersectionRatio >= 0.2) {
          // Add a class when the element is 20% or more inside the inner window
          entry.target.classList.add("fadeInLeft");
          entry.target.classList.add("animated");

          observer.unobserve(entry.target); // Stop observing once the class is added
        }
      });
    };

    // Creating an Intersection Observer instance
    const observer = new IntersectionObserver(intersectionCallback, {
      threshold: 0.2, // Trigger the callback when 20% of the target is visible
    });

    // Selecting all elements with the class 'target-element'
    const targetElements = document.querySelectorAll(".who .card");

    // Start observing each target element
    targetElements.forEach((targetElement) => {
      observer.observe(targetElement);
    });

    const questions = document.querySelectorAll(".question-container");
    const faqArrow = document.getElementById("faq-arrow");

    // questions.forEach(question => {
    //     question.addEventListener('click', () => {
    //         if (question.classList.contains('collapsed')) {
    //             question.classList.add('show')
    //             question.classList.remove('collapsed')
    //             question.classList.height = 'fit-content';
    //             question.querySelector('.question .faq-ver').style.transform = 'rotate(90deg)'
    //             // question.querySelector('.question img').style.transform = 'rotate(180deg)'
    //         } else {
    //             question.classList.add('collapsed')
    //             question.classList.remove('show')
    //             question.querySelector('.question .faq-ver').style.transform = 'rotate(0deg)'
    //             // question.querySelector('.question img').style.transform = 'rotate(0deg)'
    //             question.classList.height = '0px';
    //         }
    //     })
    // });

    //card hover
    const hoverCards = document.querySelectorAll(
      ".who .grid .info .cards .card"
    );
    const hoverImg = document.getElementById("hover-img");

    const hoverImgs = [
      "/global/dmx/images/who/1.jpg",
      "/global/dmx/images/who/2.jpg",
      "/global/dmx/images/who/3.jpg",
      "/global/dmx/images/who/4.jpg",
      "/global/dmx/images/who/6.jpg",
      "/global/dmx/images/who/7.jpg",
    ];

    hoverCards.forEach((card) => {
      card.addEventListener("mouseover", () => {
        // console.log(card.id)
        replaceImage(card.id);
      });
    });

    function replaceImage(id) {
      if (id == "wp") hoverImg.src = hoverImgs[5];
      else if (id == "bo") hoverImg.src = hoverImgs[2];
      else if (id == "s") hoverImg.src = hoverImgs[4];
      else if (id == "hm") hoverImg.src = hoverImgs[0];
      else if (id == "g") hoverImg.src = hoverImgs[3];
      else if (id == "f") hoverImg.src = hoverImgs[1];
    }

    //Courses
    let course1 = document.getElementById("course-1");
    let course2 = document.getElementById("course-2");
    let course3 = document.getElementById("course-3");
    let course4 = document.getElementById("course-4");
    let course5 = document.getElementById("course-5");

    let play = true;

    function showCourse(coursenumber) {
      if (coursenumber == 1) {
        course1.style.display = "grid";
        course2.style.display = "none";
        course3.style.display = "none";
        course4.style.display = "none";
        course5.style.display = "none";
      } else if (coursenumber == 2) {
        course1.style.display = "none";
        course2.style.display = "grid";
        course3.style.display = "none";
        course4.style.display = "none";
        course5.style.display = "none";
      } else if (coursenumber == 3) {
        course1.style.display = "none";
        course2.style.display = "none";
        course3.style.display = "grid";
        course4.style.display = "none";
        course5.style.display = "none";
      } else if (coursenumber == 4) {
        course1.style.display = "none";
        course2.style.display = "none";
        course3.style.display = "none";
        course4.style.display = "grid";
        course5.style.display = "none";
      } else if (coursenumber == 5) {
        course1.style.display = "none";
        course2.style.display = "none";
        course3.style.display = "none";
        course4.style.display = "none";
        course5.style.display = "grid";
      }
    }

    const courses = document.getElementsByClassName("course-card");
    const coursesMobile = document.getElementsByClassName("course-card-mobile");
    let slideshowTimeout;

    for (let i = 0; i < courses.length; i++) {
      courses[i].addEventListener("click", function () {
        showCourse(i + 1);
        let activeCourse = document.getElementsByClassName("active");
        activeCourse[1].classList.remove("active");
        courses[i].classList.add("active");
        clearTimeout(slideshowTimeout);
      });

      // try {
      coursesMobile[i].addEventListener("click", function () {
        showCourse(i + 1);
        let activeCourse = document.getElementsByClassName("active");
        activeCourse[0].classList.remove("active");
        coursesMobile[i].classList.add("active");
        clearTimeout(slideshowTimeout);
      });
      // } catch (e) {

      // }
    }
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(".learn-quickly .ball-container", {
      scrollTrigger: {
        trigger: ".learn-quickly .blocks-container",
        scrub: true,
        start: "top 50%",
        end: "bottom 50%",
      },
      ease: "linear",
      top: "100%",
    });
    function getTimeRemaining(endtime) {
      var t = Date.parse(endtime) - Date.parse(new Date());
      var seconds = Math.floor((t / 1000) % 60);
      var minutes = Math.floor((t / 1000 / 60) % 60);
      var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      var days = Math.floor(t / (1000 * 60 * 60 * 24));
      return {
        total: t,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds,
      };
    }

    function initializeClock(id, endtime) {
      var clock = document.getElementById(id);
      var daysSpan = clock.querySelector(".days");
      var hoursSpan = clock.querySelector(".hours");
      var minutesSpan = clock.querySelector(".minutes");
      var secondsSpan = clock.querySelector(".seconds");

      function updateClock() {
        var t = getTimeRemaining(endtime);

        daysSpan.innerHTML = ("0" + t.days).slice(-2);
        hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
        minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
        secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);

        if (t.total <= 0) {
          clearInterval(timeinterval);
        }
      }

      updateClock();
      var timeinterval = setInterval(updateClock, 1000);
    }
    var deadline = "July 29 2024 07:59:59 GMT-0700";
    //   var deadline = new Date(Date.parse(new Date()) + 100 * 24 * 60 * 60 * 1000);  // sets 15 day countdown
    initializeClock("clockdiv", deadline);

    const doubtbanner = document.getElementById("floating-doubt-banner");
    let initScroll = 0;

    window.addEventListener("scroll", function (e) {
      let newScroll =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;

      if (newScroll > initScroll) {
        doubtbanner.style.bottom = "-100px";
      } else {
        doubtbanner.style.bottom = "0px";
      }

      initScroll = newScroll;
    });

    const leadForm = document.getElementById("leads");
    const leadFormClose = document.querySelector(".lead-form img");
    const openFormButtons = document.querySelectorAll(".lead-form-open");

    // openFormButtons.forEach((btn) => {
    //     btn.addEventListener('click', () => {
    //         openForm()
    //     })
    // })

    let closed = true;

    function closeForm() {
      leadForm.style.top = "-500%";
      leadForm.style.backdropFilter = "blur(0px)";

      closed = !closed;
    }

    function openForm() {
      leadForm.style.top = "50%";
      closed = !closed;
      setTimeout(() => {
        leadForm.style.backdropFilter = "blur(5px)";
      }, 200);
    }

    // leadFormClose.addEventListener('click', () => {
    // console.log(leadFormClose)
    // closeForm()
    // })

    // setTimeout(() => {
    //     openForm()
    // }, 5000);
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    setFormStatus("loading");
    const finalValues = { ...values, utm_source: utmSource };
    const jsonData = JSON.stringify(finalValues);

    fetch(`${process.env.REACT_APP_SERVER_URL}/forms/dmx`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: jsonData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setFormStatus("submitted");
        window.location.replace("/thank-you/dmx");
        return response.text();
      })
      .then((data) => {
        setFormStatus("submitted");
        window.location.replace("/thank-you/dmx");
        console.log(data);
      })
      .catch((error) => {
        console.error("There was a problem with your fetch operation:", error);
        setFormStatus("failed");
      })
      .finally(() => {
        setSubmitting(false);
      });
   
    console.log(jsonData);
  };

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    location: "",
    prefer_mode: "",
    
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[a-zA-Z][a-zA-Z ]*$/, "You cannot enter symbols")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Please enter a valid 10-digit mobile number")
      .required("Phone number is required"),
    location: Yup.string()
      .min(4, "Location must be at least 4 characters")
      .required("Location is required"),

    prefer_mode: Yup.string().required("Required"),
  });

  return (
    <>
      <Helmet>
        <title>{"Digital Marketing Xperential Bootcamp"}</title>
        <meta
          name="description"
          content="Digital Marketing Xperential Bootcamp"
        />
        <meta property="og:title" content="Your Open Graph Title" />
        <meta property="og:description" content="Your Open Graph Description" />
        <meta property="og:image" content="URL to Your Image" />
        <meta property="og:url" content="URL to Your Web Page" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Your Site Name" />
        <meta property="og:locale" content="en_US" />
        <meta name="twitter:title" content="Your Twitter Title" />
        <meta name="twitter:description" content="Your Twitter Description" />
        <meta name="twitter:image" content="URL to Your Image" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div className="dmx-page pt-16">
        <div id="leads" className="leads">
          <div className="fixed-container"></div>
        </div>
        <section className="hero">
          <div className="tl" />
          <div className="info-container">
            <div className="info">
              <h1 className="heading">
                <span className="yellow-text"> DMX Bootcamp</span> <br />
                makes you a real marketer
              </h1>
              <p className="sublogo">
                Be a part of a comprehensive Digital Marketing bootcamp designed
                to transform you into a master in the competitive landscape
              </p>
              <div className="chip-container">
                <a href="#lead-form-redirect">
                  <button className="chip">
                    <img src="/global/icons/courses.webp" alt="admissions" />
                    <p>Download the Brochure </p>
                  </button>
                </a>
                {/* <button className="chip">
                        <img src="/global/icons/admissions.webp" alt="admissions">
                        <p>Are you a right fit?</p>
                    </button> */}
              </div>
              <div className="divider"></div>
            </div>
          </div>

          <div className="">
            <div className="video">
              <LiteYouTubeEmbed
                className=""
                style={{ width: "90%" }}
                id="recjZMPCR8A"
                title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
              />
              {/* <LiteYouTubeEmbed
                            style={{ backgroundImage: 'url("/pages/dmx/images/dmx-thumbnail.webp")' }}
                            videoid="recjZMPCR8A"
                            playlabel="Digital Marketing X"
                        /> */}
            </div>
          </div>
          <div>
            <div className="-translate-y-52" id="lead-form-redirect"></div>
            <div className="inputs-container">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                id="lead-form"
              >
                {({ isSubmitting }) => (
                  <Form
                    id="contact-form"
                    style={{ padding: "2rem" }}
                    className="lead-form dark mt-6 inputs-container flex flex-col gap-y-4"
                  >
                    <h5 className="text-lg font-semibold text-black text-center">
                      Enquire Today
                    </h5>
                    <div>
                      <Field
                        id="name"
                        className="border-black"
                        placeholder="Enter Your Name"
                        type="text"
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="email"
                        placeholder="Enter Your Email"
                        type="email"
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        id="phone"
                        placeholder="Enter Your Phone No"
                        type="tel"
                        name="phone"
                      />
                      <span className=" text-yellow-500 p-2 m-2">
                        {" "}
                        *Preferably WhatsApp Number
                      </span>
                      <ErrorMessage
                        name="phone"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        name="location"
                        rows={3}
                        id="location"
                        placeholder="Enter Your Location"
                        type="text"
                      />
                      <ErrorMessage
                        name="location"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        as="select"
                        id="prefer_mode"
                        name="prefer_mode"
                        placeholder="Select Your Purpose"
                      >
                        <option selected value="" hidden>
                          What's Your Preferred Mode Of Class ?
                        </option>

                        <option value="Online">Online</option>
                        <option value="Offline">Offline</option>
                        <option value="Anything Is Fine">
                          Anything Is Fine
                        </option>
                      </Field>
                      <ErrorMessage
                        name="prefer_mode"
                        component="div"
                        style={{ color: "red" }}
                      />
                    </div>
                    <div>
                      <Field
                        type="hidden"
                        name="utm_source"
                        value={utmSource}
                      />
                    </div>
                    <div className="center">
                      <button
                        type="submit"
                        className="black submit-btn w-full"
                        disabled={
                          isSubmitting ||
                          formStatus == "loading" ||
                          formStatus == "submitted"
                        }
                      >
                        {formStatus === "loading" ? (
                          <Loader />
                        ) : formStatus === "failed" ? (
                          "Failed, Submit Again"
                        ) : formStatus === "submitted" ? (
                          "Form Has Been Submitted"
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                    <p className="text-xs font-medium text-black pl-1">
                      * Submit to download brochure
                    </p>
                    <ErrorMessage
                      name="general"
                      component="div"
                      style={{ color: "red" }}
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className="usp-container">
            <div className="usp">
              <h3>Duration</h3>
              <h2>100+ Sessions</h2>
              <h4>3 Hours a Day (Monday - Saturday)</h4>
            </div>
            <div className="divider" />
            <div className="usp">
              <h3>Mode</h3>
              <h2>Hybrid Classes</h2>
              <h4>Offline / Online</h4>
            </div>
            <div className="divider" />
            <div className="usp">
              <h3>Format</h3>
              <h2>Xperiential</h2>
              <h4>Learn it by Doing it</h4>
            </div>
            <div className="divider" />
            <div className="usp">
              <h3>Cohort</h3>
              <h2>June 18th</h2>
              <h4>2024</h4>
            </div>
          </div>
        </section>

        <div id="highlights-a" style={{ transform: "translateY(-50px)" }} />
        <section className="highlights bg-black-grain">
          <h2 className="heading">Take a peek into the Bootcamp details</h2>
          <div className="grid">
            <div className="point">
              <p className="yellow-text">10+</p>
              <h4 className="">Case Studies</h4>
              <h3>Learn by decoding cases to analyze the practical issues</h3>
            </div>
            <div className="point">
              <p className="yellow-text">Activity Based</p>
              <h4 className="">Experiential Learning</h4>
              <h3> Experience the subject by performing group activities</h3>
            </div>
            <div className="point">
              <p className="yellow-text">Student Buddy</p>
              <h4 className="">Overall Guidance</h4>
              <h3>
                Throughout the BootCamp, students will have guidance for a
                better experience{" "}
              </h3>
            </div>
            <div className="point">
              <p className="yellow-text">10</p>
              <h4 className="">Marketing Activities </h4>
              <h3>Intense marketing rounds to master in ads</h3>
            </div>
            <div className="point">
              <p className="yellow-text">100+</p>
              <h4 className="">Sessions</h4>
              <h3>Daily 3 hours of intense training to shape your career</h3>
            </div>
            <div className="point">
              <p className="yellow-text">17 </p>
              <h4 className="">Modules </h4>
              <h3>
                A comprehensive curriculum for in-depth subject understanding
              </h3>
            </div>
          </div>
        </section>
        <section className="who bg-white-grain">
          <h2 className="heading">Our programs are crafted for</h2>
          <div className="grid">
            <div className="info">
              <div className="cards">
                <div id="wp" className="card">
                  <h3>Working Professionals</h3>
                  <p>
                    Eager to navigate the ever-evolving digital landscape and
                    stay ahead in their careers.
                  </p>
                </div>
                <div id="bo" className="card">
                  <h3>Business Owners</h3>
                  <p>
                    Double revenues by aligning with ever-changing trends for
                    sustained relevance.
                  </p>
                </div>
                <div id="s" className="card">
                  <h3>Students</h3>
                  <p>
                    Aspiring to acquire new skills that drive positive change in
                    the digital realm.
                  </p>
                </div>
                <div id="hm" className="card">
                  <h3>Professionals in Transition</h3>
                  <p>
                    Aiming to actively transit their careers into the online
                    world and explore new opportunities.
                  </p>
                </div>
                <div id="g" className="card">
                  <h3>Graduates</h3>
                  <p>
                    Ready to start a career in the digital domain and make a
                    significant impact.
                  </p>
                </div>
                <div id="f" className="card">
                  <h3>Freelancers</h3>
                  <p>
                    Desiring to target larger clients, enhance their skills, and
                    boost their revenue streams.
                  </p>
                </div>
              </div>
            </div>
            <div className="banner">
              <img id="hover-img" src="/pages/dmx/images/who/1.jpg" alt="" />
            </div>
          </div>
        </section>
        <div id="curriculum-a" style={{ transform: "translateY(-50px)" }} />
        <section className="program-highlights bg-black-grain">
          <div>
            <h2 className="heading">Here's What You Will Learn</h2>
            <div className="gridd">
              <div className="slider">
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week1 = setLearnWeeks({
                          ...learnWeeks,
                          week1: !learnWeeks.week1,
                        });
                      }}
                      className={`${
                        learnWeeks.week1 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 1 &amp; 2</p>
                      <h3 className="question">
                        Fundamentals of Digital Marketing, Website Creation, SEO
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding Marketing, Digital Marketing "
                          />
                          <p>Understanding Marketing, Digital Marketing</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Difference between traditional and digital marketing
      "
                          />
                          <p>
                            Difference between traditional and digital marketing
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction of website planning"
                          />
                          <p>Introduction of website planning</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding domain, hosting and themes"
                          />
                          <p>Understanding domain, hosting and themes</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Process of WordPress installation and configuration
       "
                          />
                          <p>
                            Process of WordPress installation and configuration
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding essential plugins and content planning
       "
                          />
                          <p>
                            Understanding essential plugins and content planning
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Process of WordPress customisation and design "
                          />
                          <p>Process of WordPress customisation and design</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Technical and security measures of the website
       "
                          />
                          <p>Technical and security measures of the website</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Launching and maintenance of the website
       "
                          />
                          <p>Launching and maintenance of the website</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding On-Page, Off-Page & Technical SEO
      "
                          />
                          <p>
                            Understanding On-Page, Off-Page &amp; Technical SEO
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="SEO Analysis, Reporting, Best Practices and Updates
       "
                          />
                          <p>
                            SEO Analysis, Reporting, Best Practices and Updates
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding Local SEO Optimization
       "
                          />
                          <p>Understanding Local SEO Optimization</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Exploring SEO Tools and Resources
       "
                          />
                          <p>Exploring SEO Tools and Resources</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="SEO Case Study and Practical Applications
       "
                          />
                          <p>SEO Case Study and Practical Applications</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="3 Case Studies | 2 Assessments | 1 Group Learning Activity
      "
                          />
                          <p>
                            1 Case Studies | 2 Assessments | 1 Group Learning
                            Activity
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week2 = setLearnWeeks({
                          ...learnWeeks,
                          week2: !learnWeeks.week2,
                        });
                      }}
                      className={`${
                        learnWeeks.week2 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 3 &amp; 4</p>
                      <h3 className="question">
                        {" "}
                        Lead Generation, Email Marketing, Google Ads
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Identifying Target Audience and Understanding Lead Generation
       "
                          />
                          <p>
                            Identifying Target Audience and Understanding Lead
                            Generation
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding different channels for Lead Generation
       "
                          />
                          <p>
                            Understanding different channels for Lead Generation
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Content Marketing and Website Optimization for Lead Generation
       "
                          />
                          <p>
                            Content Marketing and Website Optimization for Lead
                            Generation
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Social Media, Email Marketing, SEO, Paid Ads for Lead Generation
       "
                          />
                          <p>
                            Social Media, Email Marketing, SEO, Paid Ads for
                            Lead Generation
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction of Email Marketing and the Platforms
       "
                          />
                          <p>
                            Introduction of Email Marketing and the Platforms
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Building and Managing Email Lists
       "
                          />
                          <p>Building and Managing Email Lists</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Designing Effective Email Marketing Campaigns 
       "
                          />
                          <p>Designing Effective Email Marketing Campaigns</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Measurement, Troubleshooting and Optimization of Email Marketing Campaigns
      "
                          />
                          <p>
                            Measurement, Troubleshooting and Optimization of
                            Email Marketing Campaigns
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Google Ads with Ad Account Setup
      "
                          />
                          <p>
                            Introduction to Google Ads with Ad Account Setup
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding keywords, keyword research and match types
       "
                          />
                          <p>
                            Understanding keywords, keyword research and match
                            types
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Setup an ad campaign with targeting options and placement
       "
                          />
                          <p>
                            Setup an ad campaign with targeting options and
                            placement
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding ad performance, quality score, ad budgeting and big strategies
       "
                          />
                          <p>
                            Understanding ad performance, quality score, ad
                            budgeting and big strategies
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Google Display Network, Video Ads, YouTube Advertising
       "
                          />
                          <p>
                            Google Display Network, Video Ads, YouTube
                            Advertising
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Google Shopping Campaigns - Setup product feeds and optimization
      "
                          />
                          <p>
                            Google Shopping Campaigns - Setup product feeds and
                            optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Conversion Tracking with ad reporting and analytics 
       "
                          />
                          <p>
                            Conversion Tracking with ad reporting and analytics
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="3 Case Studies | 3 Assessments | 2 Group Learning Activities
      "
                          />
                          <p>
                            2 Case Studies | 3 Assessments | 2 Group Learning
                            Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week3 = setLearnWeeks({
                          ...learnWeeks,
                          week3: !learnWeeks.week3,
                        });
                      }}
                      className={`${
                        learnWeeks.week3 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 5 &amp; 6</p>
                      <h3 className="question">
                        Social Media, Content Marketing, Ad sense &amp; blogging
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Fundamentals of Social Media Marketing & Strategy Development
      "
                          />
                          <p>
                            Fundamentals of Social Media Marketing &amp;
                            Strategy Development
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Choosing the right Social Media Platforms and Content Creation 
      "
                          />
                          <p>
                            Choosing the right Social Media Platforms and
                            Content Creation
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Community Building and Engagement with Emerging Trends
      "
                          />
                          <p>
                            Community Building and Engagement with Emerging
                            Trends
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Social Media Advertising Basics, Analytics and Reporting
      "
                          />
                          <p>
                            Social Media Advertising Basics, Analytics and
                            Reporting
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Influencer Marketing & Crisis Management
      "
                          />
                          <p>Influencer Marketing &amp; Crisis Management</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Developing a Content Marketing Strategy 
      "
                          />
                          <p>Developing a Content Marketing Strategy</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding Content Types and Formats
      "
                          />
                          <p>Understanding Content Types and Formats</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Content Creation, Planning and Optimization
      "
                          />
                          <p>Content Creation, Planning and Optimization</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Content Distribution, Promotion, Repurposing and Recycling
      "
                          />
                          <p>
                            Content Distribution, Promotion, Repurposing and
                            Recycling
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Google Adsense and Blogging
      "
                          />
                          <p>Introduction to Google Adsense and Blogging</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding Ad Types and Placement Strategies
      "
                          />
                          <p>Understanding Ad Types and Placement Strategies</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Exploring Content Monetization Strategies
      "
                          />
                          <p>Exploring Content Monetization Strategies</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Understanding AdSense Policies and Compliance
      "
                          />
                          <p>Understanding AdSense Policies and Compliance</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Maximizing Revenue with Adsense	
      "
                          />
                          <p>Maximizing Revenue with Adsense</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="2 Case Studies | 3 Assessments | 3 Learning Activities
      "
                          />
                          <p>
                            2 Case Studies | 3 Assessments | 3 Learning
                            Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week4 = setLearnWeeks({
                          ...learnWeeks,
                          week4: !learnWeeks.week4,
                        });
                      }}
                      className={`${
                        learnWeeks.week4 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 7 &amp; 8</p>
                      <h3 className="question">
                        {" "}
                        Affiliate Marketing, Google Analytics, ECommerce
                        Marketing
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Affiliate Marketing
      "
                          />
                          <p>Introduction to Affiliate Marketing</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Building, Optimizing and Content Creation for Affiliate Marketing
      "
                          />
                          <p>
                            Building, Optimizing and Content Creation for
                            Affiliate Marketing
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Social Media and Email Marketing for Affiliates
      "
                          />
                          <p>Social Media and Email Marketing for Affiliates</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Affiliate Link Strategies, Advance Techniques and Analyzing Metrics
      "
                          />
                          <p>
                            Affiliate Link Strategies, Advance Techniques and
                            Analyzing Metrics
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Setting Up and Navigating Google Analytics 4 
      "
                          />
                          <p>Setting Up and Navigating Google Analytics 4</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Events, Conversions, Reports and Dashboards in GA4
      "
                          />
                          <p>
                            Events, Conversions, Reports and Dashboards in GA4
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="User-Centric Analysis and E-commerce Tracking in GA4
      "
                          />
                          <p>
                            User-Centric Analysis and E-commerce Tracking in GA4
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Cross-Platform and Cross-Domain Tracking
      "
                          />
                          <p>Cross-Platform and Cross-Domain Tracking</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Setting up an E-commerce website with woocommerce
      "
                          />
                          <p>
                            Setting up an E-commerce website with woocommerce
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="E-commerce marketing fundamentals
      "
                          />
                          <p>E-commerce marketing fundamentals</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="SEO, SMM, Content & Email Marketing for E-Commerce
      "
                          />
                          <p>
                            SEO, SMM, Content &amp; Email Marketing for
                            E-Commerce
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Analytics and measurement with a Customer Retention Strategy 
      "
                          />
                          <p>
                            Analytics and measurement with a Customer Retention
                            Strategy
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="1 Case Study | 2 Assessments | 3 Learning Activities
      "
                          />
                          <p>
                            1 Case Study | 2 Assessments | 3 Learning Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week5 = setLearnWeeks({
                          ...learnWeeks,
                          week5: !learnWeeks.week5,
                        });
                      }}
                      className={`${
                        learnWeeks.week5 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 9 &amp; 10</p>
                      <h3 className="question">
                        Google Tag Manager, Ads Mastery, Advanced SMM
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Fundamentals of Tags, Triggers, and Variables in GTM
       "
                          />
                          <p>
                            Fundamentals of Tags, Triggers, and Variables in GTM
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Container and Workspace Management
      "
                          />
                          <p>Container and Workspace Management</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Implementing Basic Tracking & Advanced Tracking with GTM
      "
                          />
                          <p>
                            Implementing Basic Tracking &amp; Advanced Tracking
                            with GTM
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="E-commerce Tracking, Cross-Domain Tracking and Subdomains
      "
                          />
                          <p>
                            E-commerce Tracking, Cross-Domain Tracking and
                            Subdomains
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Custom JavaScript Variables and Macros
      "
                          />
                          <p>Custom JavaScript Variables and Macros</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Google Tag Manager and Google Analytics Integration
      "
                          />
                          <p>
                            Google Tag Manager and Google Analytics Integration
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Campaign Structuring in Google Ads
      "
                          />
                          <p>Advanced Campaign Structuring in Google Ads</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Ad Creation Techniques with Advanced Keyword Research
      "
                          />
                          <p>
                            Advanced Ad Creation Techniques with Advanced
                            Keyword Research
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Dynamic Remarketing Strategies with Targeting Audiences and Custom Audiences
      "
                          />
                          <p>
                            Dynamic Remarketing Strategies with Targeting
                            Audiences and Custom Audiences
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Google Display Network (GDN) Mastery & Google Shopping Campaigns
      "
                          />
                          <p>
                            Google Display Network (GDN) Mastery &amp; Google
                            Shopping Campaigns
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Conversion Tracking and Attribution Models with Advanced Analytics Integration
      "
                          />
                          <p>
                            Conversion Tracking and Attribution Models with
                            Advanced Analytics Integration
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Facebook Marketing Strategies with Ad Targeting and Custom Audiences
      "
                          />
                          <p>
                            Advanced Facebook Marketing Strategies with Ad
                            Targeting and Custom Audiences
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Facebook Ad Creatives and Video Marketing with Advanced Analytics and Reporting
      "
                          />
                          <p>
                            Facebook Ad Creatives and Video Marketing with
                            Advanced Analytics and Reporting
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="2 Case Studies | 2 Assessments | 1 Group Activity
      "
                          />
                          <p>
                            1 Case Studies | 2 Assessments | 1 Group Activity
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week6 = setLearnWeeks({
                          ...learnWeeks,
                          week6: !learnWeeks.week6,
                        });
                      }}
                      className={`${
                        learnWeeks.week6 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 11 &amp; 12</p>
                      <h3 className="question">
                        {" "}
                        Advanced SMM, Advanced SEO
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="LinkedIn Marketing Strategies with LinkedIn Ad Targeting and Analytics
      
       "
                          />
                          <p>
                            LinkedIn Marketing Strategies with LinkedIn Ad
                            Targeting and Analytics
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Video Marketing on LinkedIn and Cross-Platform Strategies
      
       "
                          />
                          <p>
                            Video Marketing on LinkedIn and Cross-Platform
                            Strategies
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Video Marketing Strategies, Content Creation and Optimization
      
       "
                          />
                          <p>
                            Advanced Video Marketing Strategies, Content
                            Creation and Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Technical SEO Mastery
      
       "
                          />
                          <p>Technical SEO Mastery</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced On-Page SEO Techniques
      
       "
                          />
                          <p>Advanced On-Page SEO Techniques</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Off-Page SEO Strategies
      
       "
                          />
                          <p>Advanced Off-Page SEO Strategies</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Local SEO Optimization
      
       "
                          />
                          <p>Local SEO Optimization</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Technical SEO Audits and Troubleshooting
      
       "
                          />
                          <p>Technical SEO Audits and Troubleshooting</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="SEO for E-commerce Websites
      
       "
                          />
                          <p>SEO for E-commerce Websites</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Analytics Integration
      
       "
                          />
                          <p>Advanced Analytics Integration</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Voice Search and Emerging SEO Trends
      
       "
                          />
                          <p>Voice Search and Emerging SEO Trends</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="
                                        SEO Client Management and Reporting
       "
                          />
                          <p>SEO Client Management and Reporting</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Ethical SEO Practices and Legal Considerations
      
       "
                          />
                          <p>Ethical SEO Practices and Legal Considerations</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="1 Case Study | 2 Assessments | 1 Learning Activities
      
       "
                          />
                          <p>
                            1 Case Study | 2 Assessments | 1 Learning Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week7 = setLearnWeeks({
                          ...learnWeeks,
                          week7: !learnWeeks.week7,
                        });
                      }}
                      className={`${
                        learnWeeks.week7 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 13 &amp; 14 </p>
                      <h3 className="question">
                        E-Commerce Marketing, Display Advertising, Online
                        Reputation Management
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Foundation of E-commerce Marketing and Advanced Product Marketing Strategies
      
       "
                          />
                          <p>
                            Foundation of E-commerce Marketing and Advanced
                            Product Marketing Strategies
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Influencer Marketing and Partnerships
      
      
       "
                          />
                          <p>Influencer Marketing and Partnerships</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Analytics and Data-Driven Decision Making
      
      
       "
                          />
                          <p>Analytics and Data-Driven Decision Making</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced E-commerce Advertising Tactics
      
      
       "
                          />
                          <p>Advanced E-commerce Advertising Tactics</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Display Advertising Beyond Google Ads and Meta
      
      
       "
                          />
                          <p>
                            Introduction to Display Advertising Beyond Google
                            Ads and Meta
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Setting Up Campaigns on Alternative Platforms
      
      
       "
                          />
                          <p>Setting Up Campaigns on Alternative Platforms</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Campaign Strategy, Planning, Creative Design and Optimization
      
      
       "
                          />
                          <p>
                            Campaign Strategy, Planning, Creative Design and
                            Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Ad Formats and Placements on Alternative Platforms
      
      
       "
                          />
                          <p>
                            Ad Formats and Placements on Alternative Platforms
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Budgeting and Bidding Strategies with Tracking and Analytics on Selected Platforms
      
      
       "
                          />
                          <p>
                            Budgeting and Bidding Strategies with Tracking and
                            Analytics on Selected Platforms
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Online Reputation Management
      
      
       "
                          />
                          <p>Introduction to Online Reputation Management</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Monitoring Online Presence and Online Review Management
      
      
       "
                          />
                          <p>
                            Monitoring Online Presence and Online Review
                            Management
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Creating and Managing Online Content
      
      
       "
                          />
                          <p>Creating and Managing Online Content</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Responding to Online Criticism and Crisis Management
      
      
       "
                          />
                          <p>
                            Responding to Online Criticism and Crisis Management
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Online Branding and Personal Brand Management	
      
      
       "
                          />
                          <p>Online Branding and Personal Brand Management</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="3 Case Studies | 3 Assessments | 2 Learning Activities
      
      
       "
                          />
                          <p>
                            1 Case Studies | 3 Assessments | 2 Learning
                            Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week8 = setLearnWeeks({
                          ...learnWeeks,
                          week8: !learnWeeks.week8,
                        });
                      }}
                      className={`${
                        learnWeeks.week8 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 15 &amp; 16</p>
                      <h3 className="question">
                        Mobile Web, Advanced Email Marketing, AI Tools,
                        Freelance Projects
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Mobile Web Marketing and Website Optimization
      
       "
                          />
                          <p>
                            Introduction to Mobile Web Marketing and Website
                            Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Content Management Systems (CMS) for Mobile App
      
       "
                          />
                          <p>
                            Introduction to Content Management Systems (CMS) for
                            Mobile App
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Choosing a CMS and Setting Up the Development Environment
      
       "
                          />
                          <p>
                            Choosing a CMS and Setting Up the Development
                            Environment
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Mobile App Deployment, Distribution, Analytics and Optimization
      
       "
                          />
                          <p>
                            Mobile App Deployment, Distribution, Analytics and
                            Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Email Marketing Strategies, Design and Copywriting
      
       "
                          />
                          <p>
                            Advanced Email Marketing Strategies, Design and
                            Copywriting
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Email Automation Techniques and Deliverability Optimization
      
       "
                          />
                          <p>
                            Advanced Email Automation Techniques and
                            Deliverability Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Advanced Analytics, Reporting, Testing and Optimization
      
       "
                          />
                          <p>
                            Advanced Analytics, Reporting, Testing and
                            Optimization
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Compliance and Emerging Trends in Advanced Email Marketing
      
       "
                          />
                          <p>
                            Compliance and Emerging Trends in Advanced Email
                            Marketing
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="AI Tools Integration with Digital Marketing
      
       "
                          />
                          <p>AI Tools Integration with Digital Marketing</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Introduction to Freelancing and Building a Freelance Skillset
      
       "
                          />
                          <p>
                            Introduction to Freelancing and Building a Freelance
                            Skillset
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Creating a Freelance Portfolio 
      
       "
                          />
                          <p>Creating a Freelance Portfolio</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Exploring Online Freelance Platforms & Offline Freelance Opportunities
      
       "
                          />
                          <p>
                            Exploring Online Freelance Platforms &amp; Offline
                            Freelance Opportunities
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Pricing, Negotiation Skills, Time Management and Project Delivery
       
       "
                          />
                          <p>
                            Pricing, Negotiation Skills, Time Management and
                            Project Delivery
                          </p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Building a Personal Brand as a Freelancer
      
       "
                          />
                          <p>Building a Personal Brand as a Freelancer</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="2 Case Studies | 1 Assessment | 2 Group Learning Activities
      
       "
                          />
                          <p>
                            1 Case Studies | 1 Assessment | 2 Group Learning
                            Activities
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="section">
                  <div className="questions">
                    <div
                      onClick={() => {
                        learnWeeks.week9 = setLearnWeeks({
                          ...learnWeeks,
                          week9: !learnWeeks.week9,
                        });
                      }}
                      className={`${
                        learnWeeks.week9 ? "show" : "collapsed"
                      } question-container`}
                    >
                      <p className="name">Week 17</p>
                      <h3 className="question">
                        Final Assessments
                        <div className="faq-icon-wrapper">
                          <div className="faq-icon">
                            <div className="faq-hor" />
                            <div className="faq-ver" />
                          </div>
                        </div>
                      </h3>
                      <div className="answer">
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Mastering Sales Techniques
      
       "
                          />
                          <p>Mastering Sales Techniques</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Professional Resume and Portfolio Development
      
       "
                          />
                          <p>Professional Resume and Portfolio Development</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="In-Depth Case Study Analysis
      
       "
                          />
                          <p>In-Depth Case Study Analysis</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Comprehensive Final Assessment
      
       "
                          />
                          <p>Comprehensive Final Assessment</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Engaging Learning Activities
      
       "
                          />
                          <p>Engaging Learning Activities</p>
                        </div>
                        <div className="point">
                          <img
                            loading="lazy"
                            src="/global/icons/right-arrow.webp"
                            alt="Recognized Online Certifications & Closing Ceremony
      
       "
                          />
                          <p>
                            Recognized Online Certifications &amp; Closing
                            Ceremony
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="tools bg-black-grain">
          <h2 className="heading">Tools You Will Learn</h2>
          <div className="grid">
            <div className="tool ga-f">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/ga4.png"
                  alt=""
                />
              </div>
              <p>Google Analytics 4</p>
            </div>

            <div className="tool ga">
              <div className="center-img">
                <img className="" src="/pages/dmx/images/tools/ga.png" alt="" />
              </div>
              <p>Google Ads</p>
            </div>

            <div className="tool gtm">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/gtm.webp"
                  alt=""
                />
              </div>
              <p>Google Tag Manager</p>
            </div>
            <div className="tool gmb">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/gmb.png"
                  alt=""
                />
              </div>
              <p>Google My Business</p>
            </div>
            <div className="tool meta">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/meta.png"
                  alt=""
                />
              </div>
              <p>Meta</p>
            </div>
            <div className="tool wp">
              <div className="center-img">
                <img className="" src="/pages/dmx/images/tools/wp.png" alt="" />
              </div>
              <p>WordPress</p>
            </div>
            <div className="tool gmb">
              <div className="center-img">
                <img
                  className="invert"
                  src="/pages/dmx/images/tools/mailchimp.png"
                  alt=""
                />
              </div>
              <p>MailChimp</p>
            </div>
            <div className="tool c">
              <div className="center-img">
                <img
                  className="invert"
                  src="/pages/dmx/images/tools/canva.png"
                  alt=""
                />
              </div>
              <p>Canva</p>
            </div>
            <div className="tool gpt">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/gpt.png"
                  alt=""
                />
              </div>
              <p>Chat GPT</p>
            </div>

            <div className="tool sem">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/semrush.png"
                  alt=""
                />
              </div>
              <p>Semrush</p>
            </div>
            <div className="tool d">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/divi.png"
                  alt=""
                />
              </div>
              <p>Divi</p>
            </div>

            <div className="tool hub">
              <div className="center-img">
                <img
                  className=""
                  src="/pages/dmx/images/tools/hubspot.webp"
                  alt=""
                />
              </div>
              <p>HubSpot</p>
            </div>
          </div>
        </section>
        <section className="features bg-black-grain">
          <h1 className="heading">Beyond the learning</h1>
          <div className="features-container-mobile">
            <div className="course-selector">
              <p className="course-card-mobile  ">Portfolio Building</p>
              <p className="course-card-mobile  ">Multiple Instructors</p>
              <p className="course-card-mobile  ">
                Exclusive startup community
              </p>
              <p className="course-card-mobile  ">
                Diversified Cohort for Networking
              </p>
              <p className="course-card-mobile active">
                9 Months of Placement assistance
              </p>
            </div>
          </div>
          <div className="features-container">
            <div className="left">
              <div id="course-card-3" className="course-card  ">
                <p className=" ">Portfolio Building</p>
              </div>
              <div id="course-card-4" className="course-card  ">
                <p className=" ">Multiple Instructors</p>
              </div>
              <div id="course-card-2" className="course-card  ">
                <p>Exclusive startup community</p>
              </div>
              <div id="course-card-5" className="course-card  ">
                <p>Diversified Cohort for Networking</p>
              </div>
              <div id="course-card-1" className="course-card  active">
                <p className=" ">9 Months of Placement assistance</p>
              </div>
            </div>
            <div className="right">
              <div
                className="course-card-answer"
                id="course-5"
                style={{ display: "grid" }}
              >
                <div className="info">
                  <h3>9 Months of Placement assistance</h3>
                  <p className="subtext">
                    We support your post-bootcamp journey in the digital
                    marketing industry with our placement assistance service.
                    Our expertise aids in custom job matching, aligning your
                    skills with the right positions. Below are a couple of more
                    benefits that a student can avail:
                  </p>
                  <div className="points-container">
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Personalized Career Guidance</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Helping you craft Effective Resumes</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Sharpening Interview Skills</p>
                    </div>
                  </div>
                </div>
                <div className="banner">
                  <img src="/pages/dmx/images/slide-images/nine.webp" alt="" />
                </div>
              </div>
              <div
                className="course-card-answer"
                id="course-3"
                style={{ display: "none" }}
              >
                <div className="info">
                  <h3>Exclusive startup community</h3>
                  <p className="subtext">
                    Join our community and leverage our extensive industry
                    connections to access exclusive opportunities and enhance
                    your thought process. Below are a few benefits:
                  </p>
                  <div className="points-container">
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Priority for Hashpro Students</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Networking Excellence</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Enhanced Learning with the Community</p>
                    </div>
                  </div>
                </div>
                <div className="banner">
                  <img
                    src="/pages/dmx/images/slide-images/exclusive.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="course-card-answer"
                id="course-1"
                style={{ display: "none" }}
              >
                <div className="info">
                  <h3>Portfolio Building</h3>
                  <p className="subtext">
                    We tackle the challenge of portfolio presentation by
                    actively guiding students in building and showcasing their
                    work effectively. We achieve it by working on below aspects:{" "}
                  </p>
                  <div className="points-container">
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Projects after Major Modules</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>
                        Strategic Framework Guidance for Effective Portfolio
                        Presentation
                      </p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Interview Skill Enhancement for Job Success</p>
                    </div>
                  </div>
                </div>
                <div className="banner">
                  <img
                    src="/pages/dmx/images/slide-images/portfolio.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="course-card-answer"
                id="course-2"
                style={{ display: "none" }}
              >
                <div className="info">
                  <h3>Multiple Instructors</h3>
                  <p className="subtext">
                    We recognize the distinct value each teacher brings to a
                    student's life. Our diverse team of instructors, each an
                    expert in their field, ensures a comprehensive learning
                    experience. Explore varied perspectives on subjects like:
                  </p>
                  <div className="points-container">
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>
                        {" "}
                        <b> Content Creation</b>: Vital in today's digital
                        landscape.
                      </p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>
                        {" "}
                        <b> Video Creation</b>: Crucial in a competitive world.
                      </p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>
                        {" "}
                        <b> Sales Mastery</b>: Gain insights into business
                        growth strategies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="banner">
                  <img
                    src="/pages/dmx/images/slide-images/multiple.webp"
                    alt=""
                  />
                </div>
              </div>
              <div
                className="course-card-answer"
                id="course-4"
                style={{ display: "none" }}
              >
                <div className="info">
                  <h3>Diversified Cohort for Networking</h3>
                  <p className="subtext">
                    Cohort fundamentally is diversified augmenting opportunities
                    for students to develop themselves alongside their peers.
                    Added advantages of offline learning are:
                  </p>
                  <div className="points-container">
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Peer-to-Peer Learning </p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Group Activities</p>
                    </div>
                    <div className="points">
                      <img src="/global/icons/right-arrow.webp" />
                      <p>Exchange of unique ideas and learning from it</p>
                    </div>
                  </div>
                </div>
                <div className="banner">
                  <img
                    src="/pages/dmx/images/slide-images/diversified.webp"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div id="team-a" style={{ transform: "translateY(-50px)" }} />
        {/* style={{backgroundImage:'url(/global/images/grid-pattern.webp)'}} */}
        <section className="crafters bg-white-grain" id="courses">
          <h1 className="heading">
            {" "}
            <span className="animated">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 150"
                preserveAspectRatio="none"
              >
                <path d="M9.3,127.3c49.3-3,150.7-7.6,199.7-7.4c121.9,0.4,189.9,0.4,282.3,7.2C380.1,129.6,181.2,130.6,70,139 c82.6-2.9,254.2-1,335.9,1.3c-56,1.4-137.2-0.3-197.1,9"></path>
              </svg>
              Learn from the unconventional
            </span>
          </h1>
          <div className="splide-courses splide">
            <Splide
              options={{
                perPage: "auto",
                focus: "left",
                gap: "100px",
                pagination: false,
                autoplay: true,
                interval: 3500,
                rewind: true,
                isNavigation: true,
                breakpoints: {
                  800: {
                    perPage: 1,
                  },
                  1300: {
                    perPage: 2,
                  },
                  1500: {
                    perPage: 3,
                  },
                },
              }}
            >
              <SplideSlide>
                <div className="faculty">
                  <img
                    width="250px"
                    height="250px"
                    alt="Fahad Hasan"
                    className="faculty-img"
                    src="/global/images/people/Fahad.webp"
                  />
                  <div className="text">
                    <div className="profile">
                      <p className="name">Fahad Hasan</p>
                      <p className="desc">Digital Marketing Faculty</p>
                    </div>
                    <div className="social-media">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/fahadhasan951"
                        tabIndex={-1}
                      >
                        <img
                          alt="linkedin-logo"
                          loading="lazy"
                          src="/global/icons/linkedin.webp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="faculty">
                  <img
                    width="250px"
                    height="250px"
                    alt="Vamshi Kurapati Raw Talks with VK"
                    className="faculty-img"
                    src="/global/images/people/Vamshi.webp"
                  />
                  <div className="text">
                    <div className="profile">
                      <p className="name">Vamshi Kurapati</p>
                      <p className="desc">Founder</p>
                    </div>
                    <div className="social-media">
                      <a
                        target="_blank"
                        href="https://www.youtube.com/@rawtalkswithvk"
                        tabIndex={-1}
                      >
                        <img
                          alt="youtube-logo"
                          loading="lazy"
                          src="/global/icons/youtube.webp"
                        />
                      </a>
                      <a
                        target="_blank"
                        href="https://www.instagram.com/thevamshikurapati/"
                        tabIndex={-1}
                      >
                        <img
                          alt="linkedin-logo"
                          loading="lazy"
                          src="/global/icons/instagram.webp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              {/*   <SplideSlide>
                        <div className="faculty">
                            <img width="250px" height="250px" alt="Pranavi Gupta" loading="lazy" className="faculty-img"
                                src="/global/images/people/Pranavi.webp">
                            <div className="text">
                                <div className="profile">
                                    <p className="name">Pranavi Gupta</p>
                                    <p className="desc">Co-Founder</p>
                                </div>
                                <div className="social-media">
                                    <a target="_blank" href="https://www.linkedin.com/in/pranavigupta/"
                                        tabindex="-1"><img alt="linkedin-logo" loading="lazy"
                                            src="/global/icons/linkedin.webp"></a>
                                    <a target="_blank" href="https://www.instagram.com/pranavigupta/" tabindex="-1"><img
                                            alt="instagram-logo" loading="lazy" src="/global/icons/instagram.webp"></a>
                                </div>
                            </div>
                        </div>
                      </SplideSlide> */}
              <SplideSlide>
                <div className="faculty">
                  <img
                    width="250px"
                    height="250px"
                    alt="Soumya Vikay Kandi"
                    className="faculty-img"
                    src="/global/images/people/Soumya.webp"
                  />
                  <div className="text">
                    <div className="profile">
                      <p className="name">Soumya Vijay Kandi</p>
                      <p className="desc">Certified Corporate Trainer </p>
                    </div>
                    <div className="social-media">
                      <a
                        target="_blank"
                        href="https://www.linkedin.com/in/soumyavijay-kandi-74a0a6118"
                        tabIndex={-1}
                      >
                        <img
                          alt="linkedin-logo"
                          loading="lazy"
                          src="/global/icons/linkedin.webp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              {/*   <SplideSlide>
                        <div className="faculty">
                            <img width="250px" height="250px" alt="NaveenSai Kuriti" loading="lazy" className="faculty-img"
                                src="/global/images/people/Naveen.webp">
                            <div className="text">
                                <div className="profile">
                                    <p className="name">NaveenSai Kuriti</p>
                                    <p className="desc">Advisor</p>
                                </div>
                                <div className="social-media">
                                    <a target="_blank" href="https://www.linkedin.com/in/naveen-sai-kuriti-076904151"
                                        tabindex="-1"><img alt="linkedin-logo" loading="lazy"
                                            src="/global/icons/linkedin.webp"></a>
      
                                </div>
                            </div>
                        </div>
                      </SplideSlide> */}

              <SplideSlide>
                <div className="faculty">
                  <img
                    width="250px"
                    height="250px"
                    alt="Ananth Ram Gadepalli"
                    className="faculty-img"
                    src="/global/images/people/Ananth.webp"
                  />
                  <div className="text">
                    <div className="profile">
                      <p className="name">Ananth Ram Gadepalli</p>
                      <p className="desc">
                        Head of Sales, Ex-Bjyus &amp; White hat
                      </p>
                    </div>
                    <div className="social-media">
                      <a
                        target="_blank"
                        href="https://in.linkedin.com/in/ananth-gadepalli"
                        tabIndex={-1}
                      >
                        <img
                          alt="linkedin-logo"
                          loading="lazy"
                          src="/global/icons/linkedin.webp"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </section>
        <div id="outcomes-a" style={{ transform: "translateY(-50px)" }} />
        <section className="outcomes bg-black-grain">
          <h2 className="heading">Experience the Output</h2>
          <h3 className="subheading">
            Digital Marketing Xperential Bootcamp enumerates below learning
            outcomes
          </h3>
          <div className="grid">
            <div className="card">
              <h3> Crafting User-Centric Websites </h3>
              <div className="divider" />
              <p>
                Gaining expertise in creating dynamic, user-friendly websites
                using cutting-edge design principles.
              </p>
            </div>
            <div className="card">
              <h3>Optimize for Top Rankings </h3>
              <div className="divider" />
              <p>
                Acquiring hands-on experience in keyword research, on-page
                optimization, and link-building strategies.
              </p>
            </div>
            <div className="card">
              <h3>Magnetize Your Audience</h3>
              <div className="divider" />
              <p>
                Master the art of lead generation through targeted strategies,
                utilizing tools and convert potential customers.
              </p>
            </div>
            <div className="card">
              <h3>Tailor-Made Strategy</h3>
              <div className="divider" />
              <p>
                Create strategies tailored to the unique needs of specific
                businesses, integrating a mix of channels to maximum impact.
              </p>
            </div>
            <div className="card">
              <h3>Campaign Optimization Pro</h3>
              <div className="divider" />
              <p>
                Gain proficiency in planning, executing advertising campaigns
                across various platforms such as Google Ads, Meta Ads, and more.
              </p>
            </div>
            <div className="card">
              <h3>Data-Driven Decision Maker</h3>
              <div className="divider" />
              <p>
                Honing critical decision-making skills by analyzing data-driven
                insights and interpreting key performance indicators (KPIs).
              </p>
            </div>
          </div>
        </section>
        <div id="admissions-a" style={{ transform: "translateY(-50px)" }} />
        <section className="process bg-white-grain">
          <h2 className="heading">Admission Process</h2>
          <div className="steps">
            <div className="line" />
            <div className="card">
              <span>STEP 1</span>
              <h3>Schedule EDMX</h3>
              <p>
                A quick session delivered to provide complete information about
                Bootcamp.
              </p>
            </div>
            <div className="card">
              <span>STEP 2</span>
              <h3>Hashpro Assessement Test (HAT)</h3>
              <p>
                HAT is designed to evaluate a candidates Arithmetic, Analytical
                and English Skills.
              </p>
            </div>
            <div className="card">
              <span>STEP 3</span>
              <h3> Enrollment</h3>
              <p>
                Qualified candidates can then Enroll. And Yeah!!! You are now
                HashPro-er.
              </p>
            </div>
          </div>
          <div className="grid">
            <div className="placeholder" />
            <div className="second">
              <h3>DMX - Elevate Your Expertise</h3>
              <p>
                Step into the world of DMX, a program meticulously crafted for a
                sophisticated and experiential journey, blending in-depth case
                studies, specialized learning, and immersion in a dynamic
                startup environment.
              </p>
            </div>
          </div>
        </section>
        <section
          style={{
            backgroundImage:
              "linear-gradient(#00000088,#00000088),url(/pages/dmx/images/journey-bg.png)",
            backgroundSize: "129rem",
          }}
          className="learn-quickly border-t-2 border-solid border-hashpro"
        >
          <div id="art-of-influence" />
          <h2 className="heading">A speedy tour of your journey</h2>
          <div className="blocks-container">
            <div className="line-container"></div>
            <div className="ball-container" />
            <div className="block one">
              <div className="text">
                <h4 className=""> 📋 Selected Onboarding</h4>
                <p>
                  The cohort has selected individuals who thrive to delve into
                  the real challenges of building a career in Digital Marketing.
                  Get trained with a class full of enthusiasts.
                </p>
              </div>
              <div />
            </div>
            <div className="block two">
              <div />
              <div className="text">
                <h4 className="">🔍 Grasp through subject</h4>
                <p>
                  Upon batch start, the actual fun of learning begins. Grasp the
                  robust curriculum designed to elevate yourself in the
                  competitive digital world.
                </p>
              </div>
            </div>
            <div className="block three">
              <div className="text">
                <h4 className="">🚀 Learn by doing</h4>
                <p>
                  Learning is fun when you do it practically. We believe in
                  doing what you learn. Activities are designed to make you
                  practically apply your knowledge.
                </p>
              </div>
              <div />
            </div>
            <div className="block four">
              <div />
              <div className="text">
                <h4 className="">🌟 Showcase your learning</h4>
                <p>
                  Once learnt, showcase your learning through well-designed
                  assessments. Alongside the assessments work on projects - a
                  physical proof of your learning.
                </p>
              </div>
            </div>
            <div className="block five">
              <div className="text">
                <h4 className="">📈 Build through the Bootcamp</h4>
                <p>
                  Apply all the learnings of content creation as per psychology,
                  strategy as per audience, and optimization as per results
                  directly on the pages that you choose to build.
                </p>
              </div>
              <div />
            </div>
            <div className="block six">
              <div />
              <div className="text">
                <h4 className="">🎉 Conclude with abundance</h4>
                <p>
                  After gathering knowledge in digital marketing, gain insights
                  about sales, communication skills, freelance planning and
                  resume brilliance at the end of the Bootcamp.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="insights bg-black-grain">
          <h2 className="heading">Know the Industry better</h2>
          <p className="subheading">
            Explore the Digital Marketing Industry and its opportunities before
            you dive deeper
          </p>
          <div className="cards-container">
            <div className="card">
              <h3>Overall Market Share</h3>
              <img src="/pages/dmx/images/charts/Chart-1.webp" alt="" />
            </div>
            <div className="card">
              <h3>Job Opportunities</h3>
              <img src="/pages/dmx/images/charts/Chart-2.webp" alt="" />
            </div>
            <div className="card">
              <h3>Projected Job Growth</h3>
              <img src="/pages/dmx/images/charts/Chart-3.webp" alt="" />
            </div>
          </div>
          {/* <div className="grid-2">
      
      
      
            <div className="card">
                <h3>Sector-Wise Job Opportunities
                </h3>
                <img src="/pages/dmx/images/charts/Chart-4.webp" alt="">
            </div>
            <div className="card">
                <h3>Overall Market Share</h3>
      
                <img src="/pages/dmx/images/charts/Chart-5.png" alt="">
            </div>
      
        </div> */}
        </section>
        <section className="certificate bg-black-grain">
          <h2 className="heading">Success Credentials</h2>
          {/* <p>Ut reprehenderit commodo eu exercitation fugiat.</p> */}
          <div className="grid">
            <img
              className="certi"
              src="/pages/dmx/images/Certificate.webp"
              alt=""
            />
            <div className="additional">
              {/* <h3>Additional Tools</h3> */}
              <div>
                <img src="/pages/dmx/images/tools/g.webp" alt="" />
                <img src="/pages/dmx/images/tools/meta.png" alt="" />
                <img src="/pages/dmx/images/tools/hubspot.webp" alt="" />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="pricing bg-black-grain">
                    <div className="container">
                        <div className="info">
                            <div className="chip">Early Bird Offer ✨</div>
                            <h3>DMX Bootcamp </h3>
                            <h4>
                                Total Fee : <span className="new">Rs. 88,000/-</span>{" "}
                                <span className="old"> 1,08,000/-</span>{" "}
                            </h4>
                            <h5>Enrollment Fee Rs. 9,000/-</h5>
                            <h5>Training Fee Rs. 79,000/-</h5>
                            <div className="points">
                                <div className="point">
                                    <img src="/global/icons/yes.webp" alt="" />
                                    <p>100+ Days of Intensive Training</p>
                                </div>
                                <div className="point">
                                    <img src="/global/icons/yes.webp" alt="" />
                                    <p>10 Rounds of Marketing Activities</p>
                                </div>
                                <div className="point">
                                    <img src="/global/icons/yes.webp" alt="" />
                                    <p>17 Modules | 17 Assessment</p>
                                </div>
                                <div className="point">
                                    <img src="/global/icons/yes.webp" alt="" />
                                    <p>9 Months of Placement Assistance</p>
                                </div>
                            </div>
                            <p className="star-info">
                                * The Course Fee is payable through Cash, UPI, Net Banking and Credit/
                                Debit Cards. With our Corporate Financial Partnerships, you can avail
                                Education Loan on Easy EMI options.
                            </p>
                            <p className="price"> <span> ₹88,000 /-</span> &nbsp;&nbsp; (All Inclusive)</p> 
                        </div>
                    </div>
                </section> */}

        <section className="bg-cross-grid pb-4 text-center pt-12">
          <div className="border grid gap-y-4  rounded-xl text-black p-10 ">
            {/* ✦ */}
            <p className="text-white text-3xl md:text-4xl font-extrabold my-auto h-fit ">
              {" "}
              <span className="text-hashpro">
                {" "}
                Choose a{" "}
                <span className="bg-[#202020] text-white p-1 px-2 h-fit rounded-lg ">
                  {" "}
                  career
                </span>
              </span>
              <span className="text-hashpro translate-y-1 inline-block"></span>
              <br />{" "}
              <span className="block mt-4 text-3xl  border-white border-solid w-fit mx-auto font-bold">
                {" "}
                Not just a course
              </span>
            </p>
            <img
              className="invert w-20 py-4 rotate-[26deg] mx-auto block "
              src="/global/images/string.png"
              alt=""
            />
            {/* <p className='text-lg mt-3 font-medium'>Ad exercitation tempor anim adipisicing mollit nostrud adipisicing id.</p> */}
            <a href="#lead-form-redirect">
              <button className="lead-form-open yellow m-auto mt-4">
                Apply Now
              </button>
            </a>
          </div>
        </section>
        <section className="faqs bg-black-grain">
          <h2 className="heading">FAQ's</h2>
          <div className="questions">
            <div
              onClick={() => {
                learnWeeks.week11 = setLearnWeeks({
                  ...learnWeeks,
                  week11: !learnWeeks.week11,
                });
              }}
              className={`${
                learnWeeks.week11 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                What is the duration of the Bootcamp?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  The Bootcamp typically spans 17 weeks, providing an intensive
                  learning experience.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week12 = setLearnWeeks({
                  ...learnWeeks,
                  week12: !learnWeeks.week12,
                });
              }}
              className={`${
                learnWeeks.week12 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                Is this bootcamp suitable for beginners?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  Yes, the bootcamp is designed to cater to participants with
                  varying levels of expertise, including beginners.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week13 = setLearnWeeks({
                  ...learnWeeks,
                  week13: !learnWeeks.week13,
                });
              }}
              className={`${
                learnWeeks.week13 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                Are there any prerequisites for joining the Bootcamp?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  There are no strict prerequisites, but a basic understanding
                  of the English language, creative and mathematical skills are
                  beneficial.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week14 = setLearnWeeks({
                  ...learnWeeks,
                  week14: !learnWeeks.week14,
                });
              }}
              className={`${
                learnWeeks.week14 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                What is the learning format of the Bootcamp?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  The bootcamp has both online & offline as per the demand by
                  the students that includes hands-on projects, group
                  activities, and expert-led sessions.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week15 = setLearnWeeks({
                  ...learnWeeks,
                  week15: !learnWeeks.week15,
                });
              }}
              className={`${
                learnWeeks.week15 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                How much time should students dedicate outside of class hours?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  Allocate approximately 12 hours weekly for self-study and
                  project work to maximize learning.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week16 = setLearnWeeks({
                  ...learnWeeks,
                  week16: !learnWeeks.week16,
                });
              }}
              className={`${
                learnWeeks.week16 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                Are there backup classes for missed sessions?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  Yes, missed classes are supplemented with recorded sessions
                  and Q&amp;A opportunities with instructors for thorough
                  catch-up.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week17 = setLearnWeeks({
                  ...learnWeeks,
                  week17: !learnWeeks.week17,
                });
              }}
              className={`${
                learnWeeks.week17 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                What are the days and hours of the bootcamp?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  The bootcamp runs from Monday to Saturday, with classes
                  typically scheduled from 9:00 AM to 12:00 PM, allowing for a
                  comprehensive learning experience throughout the week.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week18 = setLearnWeeks({
                  ...learnWeeks,
                  week18: !learnWeeks.week18,
                });
              }}
              className={`${
                learnWeeks.week18 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                Does the bootcamp offer placement assistance?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  Yes, we provide placement assistance upon completion. However,
                  we don't guarantee placement as it depends on individual
                  capabilities and interests.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week19 = setLearnWeeks({
                  ...learnWeeks,
                  week19: !learnWeeks.week19,
                });
              }}
              className={`${
                learnWeeks.week19 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                Will this bootcamp prepare me for employment, freelance work,
                and entrepreneurship?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  Absolutely! Our bootcamp curriculum is designed to equip
                  participants with the skills and knowledge needed for various
                  career paths, whether you aim to secure a job, work as a
                  freelancer, or start your own business in the field.
                </p>
              </div>
            </div>
            <div
              onClick={() => {
                learnWeeks.week20 = setLearnWeeks({
                  ...learnWeeks,
                  week20: !learnWeeks.week20,
                });
              }}
              className={`${
                learnWeeks.week20 ? "show" : "collapsed"
              } question-container`}
            >
              <h3 className="question">
                What are the payment options available?
                <div className="faq-icon-wrapper">
                  <div className="faq-icon">
                    <div className="faq-hor" />
                    <div className="faq-ver" />
                  </div>
                </div>
              </h3>
              <div className="answer">
                <p>
                  The Bootcamp Fee is payable through Cash, UPI, Net Banking and
                  Credit/Debit Cards. With our Financial Partners, you can avail
                  Education Loans and pay via EMI's
                </p>
              </div>
            </div>
          </div>
        </section>

        <div id="floating-doubt-banner" className="doubt-banner">
          <div id="clockdiv">
            <div>
              <span className="days"></span>
              <div className="smalltext">Days</div>
            </div>
            <div>
              <span className="hours"></span>
              <div className="smalltext">Hours</div>
            </div>
            <div>
              <span className="minutes"></span>
              <div className="smalltext">Minutes</div>
            </div>
            <div>
              <span className="seconds"></span>
              <div className="smalltext">Seconds</div>
            </div>
          </div>
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=917036388884&text=Hi,%20I%20saw%20your%20ad%20on%20DMX%20and%20want%20to%20know%20more%20details%20regarding%20the%20course."
              className="contact-us"
            >
              <img
                className="w-12 ml-6"
                src="/global/icons/whatsapp-fill.webp"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
