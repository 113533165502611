import React, { useEffect, useState } from "react";

import { AiFillAlert } from "react-icons/ai";
import BlogForm from "./BlogForm";

import "./blog.css"
const BlogManagement = () => {
  const [mobile, setMobile] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (window.innerWidth <= "768px") {
      setMobile(true);
    }
  });
  return (
    <div className="pt-[100px]">
      <h1 className="text-center text-[#f8d00d]">Blog management (admin)</h1>
      <div className="blog-manage flex gap-[20px] ">
        <div className="left text-white">
          <p>control Panel</p>{" "}
          <div className="controlPanl ">
            <AiFillAlert />
          </div>
        </div>
        <div className="right">
          <BlogForm />
        </div>
      </div>
    </div>
  );
};

export default BlogManagement;
