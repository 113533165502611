import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types"; // Import for type-checking

const WordRotate = ({ words, duration = 3500, framerProps, className }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, duration);

    return () => clearInterval(interval);
  }, [words, duration]);

  return (
    <div className="overflow-hidden py-2" id="animate-login-text">
      <AnimatePresence mode="wait">
        <motion.p
          key={words[index]}
          className={className}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          {...framerProps}
        >
          {words[index]}
        </motion.p>
      </AnimatePresence>
    </div>
  );
};

// PropTypes for type-checking
WordRotate.propTypes = {
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  duration: PropTypes.number,
  framerProps: PropTypes.object,
  className: PropTypes.string,
};

export default WordRotate;
