export default function CancellationAndRefundPolicy() {
  return (
    <section>
      <div className="policy-page-container">
        <div className="policy-heading">
          <h1 className="heading">Hashpro Academy Refund Policy</h1>
        </div>
        <div className="policy-body" id="Refund-Policy">
          {/* <p>
                        The refund policy of the academy stipulates that once the enrollment fee
                        is paid, it becomes non-refundable. Training Fee is refundable under the
                        circumstances of health emergency, unfortunate event of a bereavement
                        within the family, students may request for a refund. Refund of the
                        Training fee will be considered only within the first week from the
                        program start date. After the first week, refunds for the Training fee
                        will not be available.
                    </p>
                    <p>
                        You acknowledge that the program fee paid by you is non-transferable and
                        non-refundable.
                    </p>
                    <p>
                        You acknowledge that we are under no obligation to refund any fees and
                        applicable charges paid by you, in full or partially, under no
                        circumstances, including for modifying and extending the duration of the
                        program, change in the commencement date of the program, your failure to
                        attend or participate in the program, modification of the structure or
                        content of the program.
                    </p> */}

          <p>
            At Hashpro Academy, we are committed to providing high-quality
            education and a positive learning experience. Our refund policy
            outlines the terms and conditions under which refunds may be
            granted.
          </p>
          <ul>
            <li>Non-Refundable Enrollment Fee/ Admission Fee:</li>
            <p>
              The enrollment fee/ admission fee, which constitutes approximately
              10-15% of the total course fee before any applicable discounts, is
              strictly non-refundable. Once paid, this fee secures your
              admission into the program and will not be returned under any
              circumstances.
            </p>
            <p>
              The admission fee is applicable for 6 months. After this period,
              if a student wishes to join another program or the program they
              missed, they will be required to pay the admission fee again.
            </p>

            <li>Training Fee/ Course Fee Refund Policy:</li>
            <p>
              The Training Fee may only be refunded under exceptional
              circumstances, such as a medical emergency or the unfortunate
              event of a bereavement within the family. Requests for refunds
              under these conditions must be made within the first week of the
              program start date. No refunds will be granted after the first
              week, regardless of the situation.
            </p>

            <li>Non-Transferability and Non-Refundability of Program Fee:</li>
            <p>
              By enrolling in the program, you acknowledge that the program fee
              is non-transferable and non-refundable. We are under no obligation
              to refund any portion of the fees or applicable charges, under any
              circumstances. This includes, but is not limited to:
            </p>
            <ol>
              <li>Modifications or extensions to the program duration.</li>
              <li>Changes to the program commencement date.</li>
              <li>Your failure to attend or participate in the program.</li>
              <li>Alterations to the structure or content of the program.</li>
            </ol>
          </ul>
          <p>
            All payments made are final, and no exceptions will be made. A
            student's enrollment under any circumstances will not be transferred
            to any other person even if they have kinship.
          </p>
          <ul>
            <li>Transfer and Credit Options</li>
            <p>
              Students may choose to transfer their enrollment to another course
              offered by Hashpro Academy within 10 days of the original course
              start date, subject to availability and approval.
            </p>
          </ul>
          <h3>Procedure for Requesting a Refund</h3>
          <ul>
            <li>Submission:</li>
            <p>
              All training fee/ course fee refund requests are allowed only
              before the commencement of the batch. However, after the batch
              starts no refund is permitted under any circumstances. In case of
              a refund, the application must be submitted in writing to
              enquire@hashproacademy.com or via our [Contact Form/Office] at
              Hashpro Academy official office.{" "}
            </p>
            <li>Required Information:</li>
            <ol>
              <li>Name of the student</li>
              <li>Course title & Batch enrolled or was planning to enroll </li>
              <li>Reason for the refund request</li>
              <li>Refund Amount </li>
              <li>Date of enrollment</li>
            </ol>
            <li>Processing Time:</li>
            <p>
              Refunds will be processed within 7 business days of receiving the
              written/ mail request
            </p>
          </ul>
          <h3>Contact Information</h3>
          <p>
            For any questions regarding this refund policy, please contact us
            at:
          </p>
          <li>Email: enquire@hashproacademy.com </li>
          <li>Phone: +91- 7036388881</li>
          <li>
            Office Address: 3rd Floor, Barwale Chambers, Street Number 11,
            Himayatnagar, Hyderabad, Telangana 500029
          </li>
        </div>
      </div>
    </section>
  );
}
