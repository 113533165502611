import { Button, useForkRef } from "@mui/material";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { logos } from "../../Global_Components/Logos";
import Header from "../../Global_Components/Header";
import SigninImage from "./images/signin.png";
import { useEffect, useState } from "react";
import UserUtils from "../../Utils/UserUtils";
import { AuthProvider, useAuth } from "../../Providers/AuthProvider";
import { Link } from "react-router-dom";
import Toastify from "../../Global_Components/Toastify";
import WordRotate from "../Payments/WordRotate";
import googleimg from "../../Global_Components/logos/google.jpg";
import loginhplogo from "../../Global_Components/logos/hashpro-white.png";
import loginbg from "../../Global_Components/icons/grouplogin.png";
export default function Signin() {
  return (
    <AuthProvider>
      <Sign />
    </AuthProvider>
  );
}

function Sign() {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [userMail, setUserMail] = useState("");
  const { currentUser, signIn, signOut } = useAuth();

  useEffect(() => {
    console.log(currentUser);
    if (currentUser != null) {
      // Toastify.failure('Logged In ')
    }
  }, [currentUser]);

  const provider = new GoogleAuthProvider();

  const auth = getAuth();

  function signInStudent() {
    signInWithPopup(auth, provider)
      .then((result) => {
        window.location.href = "/user";
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;

        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  }

  const customStyle = {
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
  };

  return (
    <>
      <Header />
      <div id="sign-in-back">
        {currentUser == null ? (
          <>
            <section className="paynow-sec text-white">
              <div className="sign-in-img">
                <img
                  src="https://res.cloudinary.com/dnogvrwrl/image/upload/v1724737022/front-view-female-student-wearing-backpack-holding-files-blue-wall_ok3ry7.png"
                  alt="signin-img"
                />
              </div>
              <div className="container">
                <img src={loginhplogo} alt="hp-logo" id="logo-login" />

                <button onClick={signInStudent} id="login-btn">
                  Login in with
                  <img src={googleimg} alt="gogoleimg" id="google" />
                </button>
                <WordRotate
                  words={[
                    "Education is the best investment you'll ever make. 🎓💰📈",
                    "Transforming payments into progress. 💳➡️📊",
                    "Empower your potential, invest in yourself. 💪🚀",
                    "Invest today, succeed tomorrow. 💼🌟",
                  ]}
                />
              </div>
            </section>
          </>
        ) : (
          <>
            <h1 className="text-md font-semibold">
              Already Signed in as{" "}
              <span className="bg-[#131313] font-light text-sm text-white px-3 py-1 rounded-2xl my-auto">
                {" "}
                {currentUser.email}
              </span>
            </h1>
            <div className="flex h-fit mt-6 gap-3">
              <button
                onClick={() => UserUtils.signOut()}
                className=" bg-white h-fit"
              >
                Sign Out
              </button>

              <a className="text-black block" href="/user">
                <button className=" bg-white">User Dashboard</button>
              </a>
            </div>
          </>
        )}
      </div>
    </>
  );
}
