import React from "react";
import { ColorRing } from "react-loader-spinner";

const InstaLoader = () => {
  return (
    <ColorRing
      visible={true}
      height="40"
      width="40"
      ariaLabel="color-ring-loading"
      wrapperStyle={{}}
      wrapperClass="color-ring-wrapper"
      colors={['']}
    />
  );
};

export default InstaLoader;
